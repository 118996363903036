import React, { useEffect, useState } from "react";
import closedeyeIcon from '../../../assets/cursors/closed-eye.svg'; 
import jellfishSprite from '../../../assets/sprites/Jellyfish.png';

const AnimatedSprite = ({cursor}) => {
  const maxPosX = window.innerWidth
  const maxPosY = window.innerHeight 
  const startPosX = maxPosX * 0.75
  const startPosY = maxPosY * 0.5
  const wander_rangeX = 50
  const wander_rangeY = 50
  

  const [position, setPosition] = useState({ x: startPosX, y: startPosY });
  const [frameIndex, setFrameIndex] = useState(0);
  const [effect, setEffect] = useState("active");

  useEffect(() => {
    if (cursor ===  `url(${closedeyeIcon}), auto`) {
      setEffect("inactive");
    } else {
      setEffect("active");
    }
    function handleMouseMove(e) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const spriteX = position.x;
      const spriteY = position.y;
      const distance = Math.sqrt((mouseX - spriteX) ** 2 + (mouseY - spriteY) ** 2);
      if (distance < 320 && effect === "active") {
        setPosition({
          x: spriteX + (spriteX - mouseX) / distance * 10,
          y: spriteY + (spriteY - mouseY) / distance * 10,
        });
      }
    }
    const interval = setInterval(() => {
      const spriteX = position.x;
      const spriteY = position.y;
      const distance = Math.sqrt((startPosX - spriteX) ** 2 + (startPosY - spriteY) ** 2);
      setFrameIndex((frameIndex + 1) % numFrames);
      if ((spriteX < (startPosX - wander_rangeX) || spriteX > (startPosX + wander_rangeX)) && (spriteY < (startPosY - wander_rangeY) || spriteY > (startPosY + wander_rangeY))) {
        setPosition({
          x: spriteX - (spriteX - startPosX) / distance * 10,
          y: spriteY - (spriteY - startPosY) / distance * 10,
        });
        };
    }, frameDuration);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
        clearInterval(interval);
        window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [position, frameIndex, cursor, effect, startPosX, startPosY]);

  
  const frameWidth = 64;
  const numFrames = 8;
  const frameDuration = 200;
  const spriteWidth = frameWidth * numFrames;

  const posPercentX = position.x / window.innerWidth * 100
  const posPercentY = position.y / window.innerHeight * 100

  return (
    <div
      style={{
        position: "absolute",
        left: `${posPercentX}%`,
        top: `${posPercentY}%`,
        width: `${frameWidth}px`,
        height: `${frameWidth}px`,
        backgroundImage: `url(${jellfishSprite})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `-${frameIndex * frameWidth}px 0px`,
        backgroundSize: `${spriteWidth}px ${frameWidth}px`,
        transform: "translate(-50%, -50%)",
      }}
    ></div>
  );
}

export default AnimatedSprite;