import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../../AnimatedLetters";
import "./index.scss";
import CircleHighlight3 from "../CircleHighlight3";
import Portfolio from '../../../assets/images/Portfolio.png'
import Portfolio2 from '../../../assets/images/Portfolio2.png'
import Portfolio3 from '../../../assets/images/Portfolio3.png'

const PortfolioProject3 = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
    <>
        <div className="container portfolio-project">
            <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Personal Portfolio".split("")}
                        idx={11}
                    />
            </h1>
            
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>The Personal Portfolio website is a React-based web app based on the works of <a class="alterraLink" href="https://www.youtube.com/@CodewithSloba">Code with Sloba</a> and 
                    modified to meet requirements with the addition of a small mascot character floating around the home landing page that runs away from the spotlight, and returns home
                    once danger has passed. As well as the ability to temporarily turn off the spotlight by holding down the mouse button. 
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Portfolio} alt="" />
                    
                </div>
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <img src={Portfolio2} alt="" />
                    
                </div>
                <div className="portfolio-column">
                    <p>The addition of skill badges to more clearly present the languages and frameworks that I've worked with. 
                    </p>
                </div>
                
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>And the addiiton of indiviudal portfolio pages in the same design to showcase each project without the use of external dependecies. As well as small 
                        comestic differences, such as the shifting glow effect at the top of each individual portfolio page to provide some difference between the pages. 
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Portfolio3} alt="" />
                    
                </div>
            </div>
            <div>

            </div>
        </div>
        <CircleHighlight3 />
        <Loader type="pacman" />
    </>
    );
}

export default PortfolioProject3;