import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Layout from './components/Layout'
import Portfolio from './components/Portfolio'
import PortfolioProject1 from './components/Portfolio/Project1'
import PortfolioProject2 from './components/Portfolio/Project2'
import PortfolioProject3 from './components/Portfolio/Project3'
import PortfolioProject4 from './components/Portfolio/Project4'
import './App.scss'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/worldofalterra" element={<PortfolioProject1 />} />
          <Route path="/portfolio/threed" element={<PortfolioProject2 />} />
          <Route path="/portfolio/personalportfolio" element={<PortfolioProject3 />} />
          <Route path="/portfolio/pixelrpg" element={<PortfolioProject4 />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
