import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../../AnimatedLetters";
import "./index.scss";
import CircleHighlight1 from "../CircleHighlight1";
import WoA from '../../../assets/images/WoA.png'
import WoA2 from '../../../assets/images/WoA2.png'
import WoA3 from '../../../assets/images/WoA3.png'

const PortfolioProject1 = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
    <>
        <div className="container portfolio-project">
            <a href="https://www.worldofalterra.com/"><h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"World of Alterra Website".split("")}
                        idx={11}
                    />
                
            </h1></a>

            
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>Click the title to visit the website! Or click here - <a className="alterraLink" href="https://www.worldofalterra.com/">Alterra awaits!</a> </p>
                    <p> The World of Alterra Website is a backend and frontend project I created for a homebrew setting of 
                        Dungeons and Dragons 5e and other table-top systems. The project uses the Django, Python-based, 
                        Framework, alongside several JavaScript libraries to create a fully interactive experience. 
                        Key features include; a modular navbar that updates components based on the page loaded, 
                        a functional and secure login and profile system, with AWS SES integration, and a SQLite database 
                        that uses Django’s model-view-template architecture and admin controls to create a video archive and content 
                        management system for the in-built wiki.
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={WoA} alt="" />
                    
                </div>
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <img src={WoA2} alt="" />
                    
                </div>
                <div className="portfolio-column">
                    <p>The video archive utilises SQLite and Django models and admin controls to store and retrieve embedded 
                        links which can then be displayed through dynamically generated templates for individual videos, 
                        or as a grid of indexed videos with built-in pagination for the ever growing collection of content. 
                    </p>
                </div>
                
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>Similarly, the website’s built-in wiki, which also uses SQLite and dynamic templating for its content generation, 
                        comes with several additional features; such as a dynamic navigation side-tree, a search bar functionality that 
                        searches and retrieves an index of pages that contain the search parameters, and full front-end edit tools accessible 
                        by Users with the required creation, editing, and/`or removal permissions, which can be controlled and modified by 
                        Admin Users on the website’s backend Admin page. 
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={WoA3} alt="" />
                    
                </div>
            </div>
            <div>

            </div>
        </div>
        <CircleHighlight1 />
        <Loader type="pacman" />
    </>
    );
}

export default PortfolioProject1;