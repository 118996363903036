import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar/'
import './index.scss'

const Layout = () => {
  const backgroundStyle = {
    backgroundImage: 'linear-gradient(to bottom right, #05354e, #236a91, #236a91, #236a91, #05354e)',
    height: '100vh',
  };
  return (
    <div className="App" style={backgroundStyle}>
      <Sidebar />
      <div className="page">
        

        <Outlet />
        
      </div>
    </div>
  )
}

export default Layout
