import React, { useState, useEffect } from "react";
import './index.scss';
import closedeyeIcon from '../../../assets/cursors/closed-eye.svg'; // import the closed eye icon image

const Spotlight = ({cursor}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [effect, setEffect] = useState("visible");

  useEffect(() => {
    if (cursor ===  `url(${closedeyeIcon}), auto`) {
      setEffect("hidden");
    } else {
      setEffect("visible");
    }
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [cursor]);



  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: position.y - 350,
          left: position.x - 300,
          width: 600,
          height: 600,
          borderRadius: "50%",
          boxShadow: "0 0 50px 50px rgba(255, 255, 255, 0.1)",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          visibility: effect,   
        }}
      />
    </div>
  );
};

export default Spotlight;