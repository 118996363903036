import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/BigRedC.png'
import Logo from './Logo'
import Spotlight from "./Spotlight";
import Sprite from "./Sprite";
import './index.scss'
import openEyeIcon from '../../assets/cursors/eye.svg'; // import the open eye icon image
import closedeyeIcon from '../../assets/cursors/closed-eye.svg'; // import the closed eye icon image

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [cursor, setCursor] = useState(`url(${openEyeIcon}), auto`);
  const nameArray = ['h', 'r', 'i', 's', ' ', 'M', 'c', 'k', 'e', 'n', 'z', 'i', 'e']
  const jobArray = [
    'S',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]
  
  useEffect(() => {
    const timer = setTimeout(() => {
        setLetterClass('text-animate-hover');
    }, 3000);

    return () => {
        clearTimeout(timer);
    }
  });

  const handleMouseDown = (event) => {
    setCursor(`url(${closedeyeIcon}), auto`);
  };

  const handleMouseUp = (event) => {
    setCursor(`url(${openEyeIcon}), auto`);
  };



  return (
    <>
      <div className="container home-page" 
      style={{ cursor }} 
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      >
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img
              src={LogoTitle}
              alt="JavaScript Developer Name, Web Developer Name"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={10}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={17}
            />
          </h1>
          <h2>Coder / Full Stack Developer / Game Developer</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>
        <Sprite cursor={cursor} />
        <Logo cursor={cursor} />
        <Spotlight cursor={cursor} />
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Home
