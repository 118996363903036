import { useEffect, useState } from 'react'
import {
  faPython,
  faCss3,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timer = setTimeout(() => {
        setLetterClass('text-animate-hover');
    }, 3000);

    return () => {
        clearTimeout(timer);
    }
});
  return (
    <>
      <div className="container about-page">
        <div className="text-zone2">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm an ambitious coder, skilled in both full-stack and game development, looking to take my skills to the next level 
            and find opportunities to work with the latest technologies on challenging and diverse projects.
          </p>
          <p align="LEFT">
            I'm confident, naturally curious, upskill quickly, and specialise in automation and holistic design, with a passion for interactive experiences 
            and all things story-driven.
          </p>
          <p>
            If I need to define myself in one sentence that would be as a skill-monkey developer, with specialisations in automation and rapid-development,
            with experience working autonomously and leading project workstreams. 
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faPython} color="#5bf5b5" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" width="50%" height="50%" alt="Language Icon"/>
            </div>
          </div>
        </div>

        <div className="display-zone">
          <div class="grid-container">
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg" alt="Language Icon"/>
                        <h3>SQL</h3> 
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" alt="Language Icon"/>
                        <h3>Python</h3>
                     </div>
                </div>
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" alt="Language Icon"/>
                        <h3>Django</h3>
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                       <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/godot/godot-original.svg" alt="Language Icon"/>
                        <h3>Godot</h3> 
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                       <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" alt="Language Icon"/>
                        <h3>JavaScript</h3> 
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" alt="Language Icon"/>
                        <h3>HTML</h3>
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" alt="Language Icon"/>
                        <h3>CSS</h3>
                    </div>
                </div>
                <div class="card">
                    <div class="card_head">
                        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" alt="Language Icon"/>
                        <h3>React</h3> 
                    </div>
                </div>
            </div>

          

        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
