import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../../AnimatedLetters";
import "./index.scss";
import CircleHighlight4 from "../CircleHighlight4";
import Salvos2 from '../../../assets/images/Salvos2.png'
import Savlos from '../../../assets/images/Savlos.png'
import Salvos3 from '../../../assets/images/Salvos3.png'

const PortfolioProject4 = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
    <>
        <div className="container portfolio-project">
            <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Godot 2D Pixel Art RPG".split("")}
                        idx={6}
                    />
            </h1>
            
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>The Godot 2D Pixel Art RPG is a small indie game project I created that is not yet 
                        available for public release, but which sees a small pixel art character explore a 
                        region of the Alterra TSA Altsphere while fighting monsters, collecting loot, and levelling up. 
                        The project uses the Godot Game Engine, and the Engine’s own GDScript Programming Language for all of its code. 
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Salvos2} alt="" />
                    
                </div>
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <img src={Savlos} alt="" />
                    
                </div>
                <div className="portfolio-column">
                    <p>The game has a menu system, including navigation and pause screen functionality standard game setting options, 
                        include window and volume controls, character and level selection mechanics, interactive terrain, 
                        wandering monsters with AI controls, and inventory system. 
                    </p>
                </div>
                
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>The game uses JSON files for storage, and modular functions and class inheritance for reusuable and base assets, such as items and monsters, 
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Salvos3} alt="" />
                    
                </div>
            </div>
            <div>

            </div>
        </div>
        <CircleHighlight4 />
        <Loader type="pacman" />
    </>
    );
}

export default PortfolioProject4;