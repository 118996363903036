import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../../AnimatedLetters";
import "./index.scss";
import CircleHighlight2 from "../CircleHighlight2";
import Threed from '../../../assets/images/Threed.png'
import Threed2 from '../../../assets/images/Threed2.png'
import Threed3 from '../../../assets/images/Threed3.png'

const PortfolioProject2 = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
    <>
        <div className="container portfolio-project">
            <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"3D Web Design".split("")}
                        idx={11}
                    />
            </h1>
            
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>The 3D Web Design project is a work in progress, pending a full write-up with hopefully a demo link coming soon in the near future. 
                        The project uses the Three.JS library alongside the CANNON and YUKA libraries to create an explorable 3d world with collision physics and 
                        behavioural AI enemies right in your very browser as you pilot a small hovering spaceship and take on the unknown!
                        
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Threed} alt="" />
                    
                </div>
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <img src={Threed2} alt="" />
                    
                </div>
                <div className="portfolio-column">
                    <p>
                    </p>
                </div>
                
            </div>
            <div className="portfolio-row">
                <div className="portfolio-column">
                    <p>
                    </p>
                </div>
                <div className="portfolio-column">
                    <img src={Threed3} alt="" />
                    
                </div>
            </div>
            <div>

            </div>
        </div>
        <CircleHighlight2 />
        <Loader type="pacman" />
    </>
    );
}

export default PortfolioProject2;