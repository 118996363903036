import './index.scss'
import { useState } from 'react'
import LogoS from '../../assets/images/BigRedC.png'
import LogoSubtitle from '../../assets/images/cmckenzie.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
  faFile,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'
import CVfile from '../../assets/files/Resume.pdf'

const Sidebar = () => {
  const [showNav, setShowNav] = useState(false);
  const pdfUrl = CVfile;
  
  const handleDownload = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="nav-bar">
      <Link 
        className="logo"
        to="/"
        onClick={() => setShowNav(false)}>
        <img src={LogoS} alt="Logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="CMckenzie" />
      </Link>
      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink 
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>

        <NavLink 
          activeclassname="active"
          className="about-link"
          to="/about"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>

        <NavLink
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e" />
        </NavLink>

            <a href="chriswmckenzie.com/Resume.pdf"
              activeclassname="active" 
              className="resume-link"
              onClick={handleDownload}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faFile}
                color="#4d4d4e"
                className="anchor-icon"
              />
            </a>

        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink>

        <FontAwesomeIcon 
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="#FE54BF"
          size="3x"
          className='close-icon' />
      </nav>
      <ul>
        <li>
          <a className="general-link"
            href="https://www.linkedin.com/in/christopher-mckenzie-a1326319a/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a className="general-link"
            href="https://github.com/cmckenzie01"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
      
        
      </ul>
      <FontAwesomeIcon 
          onClick={() => setShowNav(true)}
          icon={faBars}
          color="#FE54BF"
          size="3x"
          className='hamburger-icon' />
    </div>
  )
}

export default Sidebar
