const CircleHighlight1 = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -200,
          left: "10%",
          width: 300,
          height: 300,
          borderRadius: "50%",
          boxShadow: "0 0 50px 50px rgba(255, 255, 255, 0.2)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",       
        }}
      />
    </div>
  );
};

export default CircleHighlight1;