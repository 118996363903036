import { useState } from 'react'
import { TweenMax, Power2 } from 'gsap'
import LogoS from '../../../assets/images/BigRedC.png'
import './index.scss'

const Logo = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleLoad = () => {
    TweenMax.fromTo(
      ".solid-logo",
      { opacity: 0 },
      { opacity: 1, duration: 5, ease: Power2.easeInOut }
    )
    setIsLoaded(true)
  }

  return (
    <div className="logo-container">
      <img
        className="solid-logo"
        src={LogoS}
        alt="JavaScript, Developer"
        style={{ opacity: isLoaded ? 1 : 0 }}
        onLoad={handleLoad}
      />
    </div>
  )
}

export default Logo
